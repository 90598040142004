<template>
  <div>
    <v-dialog
      v-model="showForgetDialog"
      max-width="448"
      persistent
      :retain-focus="false"
    >
      <Alert
        :alert-visible="showAlert"
        :alert-data="alertData"
        @closeModal="isAlertClosed"
      />
      <v-card class="cart-alert">
        <v-card-title>
          <h3>نسيت كلمة المرور</h3>
        </v-card-title>
        <v-btn
          elevation="0"
          icon
          dark
          @click="showForgetDialog = false"
          class="close-dialog-icon"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- <v-card-subtitle> -->
        <p>
          يرجى إدخال عنوان بريد إلكتروني مسجل مسبقاً لدينا، ليتم ارسال رابط
          استعادة كلمة المرور
        </p>
        <!-- </v-card-subtitle> -->
        <v-form ref="formRef" class="modal-form" lazy-validation>
          <v-text-field
            v-model="form.email"
            required
            class="input-email"
            placeholder="أدخل البريد الإلكتروني"
            type="email"
            :rules="emailRules"
            @keydown.enter="sendEmail"
            validate-on-blur
            @blur="() => formRef.resetValidation()"
          />
          <v-btn
            :loading="loading"
            block
            height="56"
            @click="sendEmail"
            class="login-btn"
          >
            <span> طلب إستعادة كلمة المرور </span>
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
    <ActivationDialog
      :dialog-visible="showDialog"
      :dialog-data="dialogData"
      @closeModal="isDialogClosed"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useNuxtApp } from "#app";
import ActivationDialog from "~/components/auth/ActivationDialog.vue";
import Alert from "~/components/shared/Alert.vue";
import { useAuthStore } from "~/stores/AuthStore";

const authStore = useAuthStore();
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["closeModal"]);

const showForgetDialog = ref(false);
const showDialog = ref(false);
const dialogData = ref({});
const showAlert = ref(false);
const alertData = ref({});
const loading = ref(false);
const formRef = ref(null);
const form = ref({
  email: "",
});

const emailRules = [
  (v) => !!v || "البريد الإلكتروني مطلوب",
  (v) =>
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v,
    ) || "البريد الإلكتروني غير صحيح",
];

watch(
  () => props.dialogVisible,
  (val) => {
    if (val) {
      showForgetDialog.value = true;
    }
  },
);

watch(showForgetDialog, (val) => {
  if (!val) {
    emit("closeModal", { value: true });
  }
});

const sendEmail = async () => {
  const valid = await formRef.value.validate();
  if (valid) {
    loading.value = true;
    const formData = new FormData();
    for (const key in form.value) {
      formData.set(key, form.value[key]);
    }
    const resData = await authStore.forgotPassword(formData);

    if (resData.success) {
      dialogData.value = {
        type: "",
        msg: resData.message,
      };
      showForgetDialog.value = false;
      showDialog.value = true;
      form.value.email = "";
    } else {
      setAlertData(resData);
    }
    loading.value = false;
  }
};

const isDialogClosed = (payload) => {
  if (payload.value) {
    showDialog.value = false;
  }
};

const setAlertData = (data) => {
  alertData.value = data;
  showAlert.value = true;
};

const isAlertClosed = (payload) => {
  if (payload.value) {
    showAlert.value = false;
  }
};
</script>

<style lang="scss" scoped>
.cart-alert {
  width: 448px;
  min-height: 387px;
  padding: 20px 40px;
  border-radius: 23px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: none;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
  position: relative;
  .close-dialog-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #c7c7c7 !important;
  }
  .v-card-title {
    font-size: 32px;
    line-height: 1.81;
  }
  .v-card__subtitle {
    font-size: 15px;
    font-weight: bold;
    padding: 0px 12%;
    color: #000;
  }
  .login-btn {
    margin: 15px 0px;
    border-radius: 13px;
    border: solid 1px rgba(0, 0, 0, 0);
    background-color: #464688 !important;

    span {
      font-size: 15px;
      font-weight: bold;
      line-height: 1.87;
      color: #fff;
    }
  }
}

@media (max-width: 600px) {
  .cart-alert {
    padding: 10px 15px;
    border-radius: 18px;
    h2 {
      font-size: 26px;
    }
    .v-card__subtitle {
      padding: 0px 5%;
    }
  }
}
</style>
